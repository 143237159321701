<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    role="presentation"
  >
    <g :fill="iconColor">
      <component :is="iconName" />
    </g>
  </svg>
</template>

<script>
import IconAccountEdit from '@/components/icons/IconAccountEdit'
import IconAddEvent from '@/components/icons/IconAddEvent'
import IconCalendar from '@/components/icons/IconCalendar'
import IconCaution from '@/components/icons/IconCaution'
import IconCheck from '@/components/icons/IconCheck'
import IconClock from '@/components/icons/IconClock'
import IconComment from '@/components/icons/IconComment'
import IconGoogle from '@/components/icons/IconGoogle'
import IconInfo from '@/components/icons/IconInfo'
import IconLeftArrow from '@/components/icons/IconLeftArrow'
import IconLock from '@/components/icons/IconLock'
import IconMegaphone from '@/components/icons/IconMegaphone'
import IconProposal from '@/components/icons/IconProposal'
import IconRightArrow from '@/components/icons/IconRightArrow'
import IconSend from '@/components/icons/IconSend'
import IconStar from '@/components/icons/IconStar'
import IconUserPlus from '@/components/icons/IconUserPlus'
import IconXMark from '@/components/icons/IconXMark'

export default {
  components: {
    'account-edit': IconAccountEdit,
    'add-event': IconAddEvent,
    calendar: IconCalendar,
    caution: IconCaution,
    check: IconCheck,
    clock: IconClock,
    comment: IconComment,
    google: IconGoogle,
    info: IconInfo,
    'left-arrow': IconLeftArrow,
    lock: IconLock,
    megaphone: IconMegaphone,
    proposal: IconProposal,
    'right-arrow': IconRightArrow,
    send: IconSend,
    star: IconStar,
    'user-plus': IconUserPlus,
    'x-mark': IconXMark
  },
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    iconName: {
      type: String,
      required: true
    },
    viewBox: {
      type: String,
      default: '0 0 18 18'
    }
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
</style>
